import _ from "lodash";

//////////////
// DEV MODE //
//////////////
export const DEV_MODE = false;
export const DEV_TYPE: string = "alpha";
//export const DEV_TYPE: string = 'dienstleister';

export const NAMING_USER_MEDIA = "_userMedia";
export const NAMING_SUSTAINABILITY_FACTOR = "_sustainability";
export const NAMING_LS_IN_MARKTGEBIET = "_active";

// URLs TO APIs
// export const URL_TO_AUTH_API = 'http://localhost:13000';
// export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
//   'http://localhost:13000/auth/v1/public/token/refresh';
// export const URL_TO_MEDIA_API = 'http://localhost:13004';
// export const URL_TO_MEDIA_SERVER = 'http://localhost:13007';
// export const URL_TO_KEYVAL_SERVER = 'http://localhost:13009';

//Liveserver
export const URL_TO_AUTH_API = "https://api.panasonic.beestate.io/auth";
export const URL_TO_REFRESH_TOKEN_AT_AUTH_API = "https://api.panasonic.beestate.io/auth/v1/public/token/refresh";
export const URL_TO_MEDIA_API = "https://api.panasonic.beestate.io/media";
export const URL_TO_MEDIA_SERVER = "https://media.panasonic.beestate.io";
export const URL_TO_LIEGENSCHAFTEN_SERVER = "https://api.panasonic.beestate.io/property";
export const URL_TO_KEYVAL_SERVER = "https://api.panasonic.beestate.io/keyval";
export const URL_TO_LVEP_SERVER = "https://api.panasonic.beestate.io/lvep";
export const URL_TO_DEFAULT_PROPERTY_IMAGE = "https://beestate.io/wp-content/uploads/2019/05/beestate_bg_digital.jpg";
export const mediaTokenSecret =
  "OSpFTPnbrPYrAYCWgS1IdsJtIFeQVhaWtffs5Icql3y5mbAtHzboO35wTi0XRwInHN8rJBT873nQAQiTEGZxQTxl6rjP5cY8e5Ejnh1oS0GtdoQxXUYwUTlt5LgIhw5p";
export const OUTPUT_FILE_HEADLINE = "Panasonic-Rahmenpreisvergabe";

//DemoServer
// export const URL_TO_AUTH_API =
//   'https://demo-multisourcing-api.serthoro.de/auth';
// export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
//   'https://demo-multisourcing-api.serthoro.de/auth/v1/public/token/refresh';
// export const URL_TO_MEDIA_API =
//   'https://demo-multisourcing-api.serthoro.de/media';
// export const URL_TO_MEDIA_SERVER =
//   'https://demo-multisourcing-media.serthoro.de';
// export const URL_TO_LIEGENSCHAFTEN_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/property';
// export const URL_TO_KEYVAL_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/keyval';
// export const URL_TO_LVEP_SERVER =
//   'https://demo-multisourcing-api.serthoro.de/lvep';
// export const URL_TO_DEFAULT_PROPERTY_IMAGE =
//   'https://beestate.io/wp-content/uploads/2019/05/beestate_bg_digital.jpg';
// export const mediaTokenSecret =
//   'pgP9dVWMtCV3YtLGZR9NQJjweFNrgfvQv2wTaQJzxr8YqTSqxVLERguTWURe5bEnS6DGrnJkwwjA9DCbQADdgv2bdMzTFAjbIszHwkEtPgyUKsVINEgZfABPmhcavW3A';
// export const OUTPUT_FILE_HEADLINE = 'Beestate-Rahmenpreisvergabe';

//USER RIGHTS
export const USER_RIGHT_DLREAD: string = "PERMISSION_DLREAD";
export const USER_RIGHT_DLWRITE_KEYVAL: string = "PERMISSION_DLWRITEKEYVAL";
export const USER_RIGHT_DLWRITE_MEDIA: string = "PERMISSION_DLWRITEMEDIA";

export const DEFAULT_FACTOR = 1000;
export const DEFAULT_CALCULATION_MULTIPLIER = 1000;
export const DEFAULT_EXPAND_MAX_DEPT_EP = 7;
export const LV_DEPTH_EP_CATALOG: number = 3; //-1 for deactivate
export const VISIBLE_PROVIDER_IN_ANALYSIS = 5;

export const DL_EP_INPUT_DISABLE_FILTER_ID = "no-filter";

//placeholder & empty stuff
export const EMPTY_FIELD_PLACEHOLDER = " — ";

//VALIDATE EMAIL REGEX
export const VALIDATE_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//TOASTS
export const pwChangedToast = "PwChanged";
export const liegenschaftDeletedToast = "LiegenschaftDeleted";
export const liegenschaftCreatedToast = "LiegenschaftCreated";

export const DURATION_NOTIFICATION_SUCCESS_LONG = 4000;
export const DURATION_NOTIFICATION_ERROR_LONG = 4000;

//HEATMAP
export const NUMBER_OF_FACTORS = 5;

export const MIN_VALUE_FACTOR = 0.1;
export const MAX_VALUE_FACTOR = 3;
export const MIN_VALUE_FACTOR_LIEGENSCHAFTEN = 0.7;
export const MAX_VALUE_FACTOR_LIEGENSCHAFTEN = 1.3;
export const MAX_NUMBER_OF_DECIMALS = 3;
export const MIN_NUMBER_OF_DECIMALS = 0;
export const MAX_F = _.max([MAX_VALUE_FACTOR, MAX_VALUE_FACTOR_LIEGENSCHAFTEN])! * DEFAULT_CALCULATION_MULTIPLIER;

//CALL STATUS
export const SERVER_RESPONSE_PENDING = "SERVER_RESPONSE_PENDING";
export const SERVER_RESPONSE_ERROR = "SERVER_RESPONSE_ERROR";
export const SERVER_RESPONSE_SUCCESS = "SERVER_RESPONSE_SUCCESS";

export const DEFAULT_IMAGE_SIZE = "MEDIUM";

//ACCEPTED FILE FORMATS
export const ACCEPTED_FORMATS_EXCEL = {
  "application/vnd.ms-excel*": [".xls", ".xlsx"],
};
export const ACCEPTED_FILE_FORMATS = {
  "image/*": [".jpg", ".jpeg", ".png"],
  "text/*": [".pdf", ".json", ".xlsx", ".xls", ".doc", ".docx", ".ppt", ".pptx", ".csv", ".txt"],
};

export const LIEGENSCHAFT_SORT_NAME = "Name";
export const LIEGENSCHAFT_SORT_CITY = "Stadt";
export const LIEGENSCHAFT_SORT_ADDRESS = "Straße & Hausnummer";
export const LIEGENSCHAFT_SORT_BUILDING_NO = "Gebäudenummer";

export const DROPDOWN_LIEGENSCHAFT_SORT = [
  LIEGENSCHAFT_SORT_NAME,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
];

export const ALPHA_DL_NOT_IN_MARKETAREA = "nicht im Marktgebiet";
export const ALPHA_DL_INCOMPLETE_PRICES = "unvollständig bepreist";
export const ALPHA_DL_NO_PRICE = "kein Preis";

export const COUNTRIES: COUNTRY[] = [
  {
    id: "DE",
    country: "Deutschland",
    regions: [
      { id: "BW", region: "Baden-Württemberg" },
      { id: "BY", region: "Bayern" },
      { id: "BE", region: "Berlin" },
      { id: "BB", region: "Brandenburg" },
      { id: "HB", region: "Bremen" },
      { id: "HH", region: "Hamburg" },
      { id: "HE", region: "Hessen" },
      { id: "MV", region: "Mecklenburg-Vorpommern" },
      { id: "NI", region: "Niedersachsen" },
      { id: "NW", region: "Nordrhein-Westfalen" },
      { id: "RP", region: "Rheinland-Pfalz" },
      { id: "SL", region: "Saarland" },
      { id: "SN", region: "Sachsen" },
      { id: "ST", region: "Sachsen-Anhalt" },
      { id: "SH", region: "Schleswig-Holstein" },
      { id: "TH", region: "Thüringen" },
    ],
  },
];

export const tags = {
  sonstiges: "Sonstiges",
  igm: "IGM",
  tgm: "TGM",
  uegm: "ÜGM",
  aufzug: "Aufzugsmanagement",
  energy: "Energiemanagement",
  waste: "Entsorgungsmanagement",
  sv: "SV-Prüfung",
};

export const LeistungsSlices: LEISTUNGSART[] = [
  {
    id: "1",
    icon: "",
    //icon: "icon-haushaelter",
    title: "Objektmanagement",
    tag: ["uegm"],
    sustainability: false,
  },
  {
    id: "2",
    icon: "",
    //icon: "icon-aufzug",
    title: "Technische Betriebsführung",
    tag: ["tgm"],
    sustainability: false,
  },
  {
    id: "3",
    icon: "",
    //icon: "icon-energie",
    title: "Infrastrukturelles GM",
    tag: ["igm"],
    sustainability: false,
  },
  {
    id: "4",
    icon: "",
    //icon: "icon-entsorgung",
    title: "Sicherheitsdienst",
    tag: ["sich"],
    sustainability: false,
  },
  {
    id: "5",
    icon: "",
    //icon: "icon-pruefer",
    title: "Sonstige Leistungen",
    tag: ["konf"],
    sustainability: false,
  },
];

export type LEISTUNGSART = {
  id: string;
  icon: string;
  title: string;
  tag: string[];
  sustainability: boolean;
};

export type COUNTRY = {
  id: string;
  country: string;
  regions: REGION[];
};

export type REGION = {
  id: string;
  region: string;
};

//CLUSTERVIEW OPTIONAL POSITION
export const prefixInactive = "inaktivBedarf_";
export const prefixActive = "aktivBedarf_";
